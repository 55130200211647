import styles from "./Filters.module.css";

import { SEARCH_ATTRIBUTES } from "../../../../../../Queries/Products";

import { STUDS_TYPES_ENUM } from "../../../../../../Queries/Products";

import { useQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";

import FilterText from "../Elements/FilterText";

import FilterBoolean from "../Elements/Boolean";

import FilterSelect from "../Elements/FilterSelect";

import FiltersReset from "../Elements/Reset";

import FilterVehicleType from "../Elements/VehicleType";

import CenterLoader from "../../../../Loaders/CenterLoader";

import FilterCheckbox from "../Elements/FilterCheckbox";

import FilterCheckboxEnum from "../Elements/FilterCheckboxEnum";

import FilterAvailability from "../Elements/FilterAvailability";

/**
 * 
 * @exports TiresFilters
 * 
 * @description This component renders the filters for the tire collection 
 * 
 * @param { Object } props
 * 
 * @param { Function } props.refetch The refetch function to refetch the tire collection
 * 
 * @param { Object } props.variables The variables to be used to refetch the tire collection
 * 
 * @returns { JSX.Element } TiresFilters
 * 
 */

export default function TiresFilters ( { refetch, variables, product_loader } ) { const 

    { t } = useTranslation (),

    { data, loading } = useQuery ( SEARCH_ATTRIBUTES ),

    /**
     * 
     * @constant { Object } componentData
     * 
     * @description This object contains the data for the filters to be rendered in the tire collection.
     * 
     */

    componentData = {

        FilterTextClient: {

            component: FilterText,

            props: { refetch : refetch, variables : variables, name : "client", placeholder : t ( "client" ), loader : product_loader },

            dataName: false,
            
            gridOptions: { gridColumn: "1 / 3" }

        },

        FilterTextProductId: {

            component: FilterText,

            props: { refetch : refetch, variables : variables, name : "id", parse : "number", placeholder : t ( "ID" ), loader : product_loader },

            dataName: false,

            gridOptions: { gridColumn: "4 / 6" }

        },

        FilterTextEan: {

            component: FilterText,

            props: { refetch : refetch, variables : variables, name : "ean", parse : "number", placeholder : t ( "EAN" ), loader : product_loader },

            dataName: false,

            gridOptions: { gridColumn: "6 / 8" }

        },

        FilterVehicleType: {

            component: FilterVehicleType,

            props: { refetch : refetch, variables : variables, name : "vehicleType", loader : product_loader},

            dataName: "vehicle_types",

            gridOptions: { gridColumn: "8 / 12", gridRow: "1 / 4" }

        },

        FilterCheckboxWidth: {

            component: FilterCheckbox,

            props: { refetch : refetch, variables : variables, name : "width", loader : product_loader, parse : "number" },

            dataName: "widths",

            gridOptions: { gridColumn: "1 / 2" }

        },

        FilterCheckboxHeight: {

            component: FilterCheckbox,

            props: { refetch : refetch, variables : variables, name : "height", loader : product_loader, parse : "float" },

            dataName: "heights",

            gridOptions: { gridColumn: "2 / 3" }

        },

        FilterCheckboxDiameter: {

            component: FilterCheckbox,

            props: { refetch : refetch, variables : variables, name : "diameter", loader : product_loader },

            dataName: "diameters",

            gridOptions: { gridColumn: "3 / 4" }

        },

        FilterMultipleSeason: {

            component: FilterCheckbox,

            props: { refetch : refetch, variables : variables, name : "season", loader : product_loader},

            dataName: "seasons",

            gridOptions: { gridColumn: "4 / 6" }

        },

        FilterSelectVendorId: {

            component: FilterCheckbox,

            props: { refetch : refetch, variables : variables, name : "vendorId", parse : "number", label : "vendor", loader : product_loader, label : true },

            dataName: "vendors",

            gridOptions: { gridColumn: "6 / 8" }

        },

        FilterMultipleManufacturer: {

            component: FilterCheckbox,

            props: { refetch : refetch, variables : variables, name : "manufacturer", loader : product_loader, textValue : true },

            dataName: "manufacturers",

            gridOptions: { gridColumn: "1 / 3" }

        },

        FilterMultipleModel: {

            component: FilterCheckbox,

            props: { refetch : refetch, variables : variables, name : "model", textValue : true, loader : product_loader },

            dataName: "models",

            gridOptions: { gridColumn: "3 / 6" }

        },

        FilterMultipleSpeedRating: {

            component: FilterCheckbox,

            props: { refetch : refetch, variables : variables, name : "speedRating", loader : product_loader},

            dataName: "speed_ratings",

            gridOptions: { gridColumn: "1 / 2" }

        },

        FilterMultipleNoiseIndex: {

            component: FilterCheckbox,

            props: { refetch : refetch, variables : variables, name : "noiseIndex", loader : product_loader},

            dataName: "noise_indexes",

            gridOptions: { gridColumn: "2 / 3" }

        },

        FilterMultipleLoadIndex: {

            component: FilterCheckbox,

            props: { refetch : refetch, variables : variables, name : "loadIndex", loader : product_loader},

            dataName: "load_indexes",

            gridOptions: { gridColumn: "3 / 4" }

        },

        FilterMultipleFuelEfficiency: {

            component: FilterCheckbox,

            props: { refetch : refetch, variables : variables, name : "fuelEfficiency", loader : product_loader},

            dataName: "fuel_efficiencies",

            gridOptions: { gridColumn: "4 / 5" }

        },

        FilterMultipleWetBreakingEfficiency: {

            component: FilterCheckbox,

            props: { refetch : refetch, variables : variables, name : "wetBreakingEfficiency", loader : product_loader},

            dataName: "wet_grip",

            gridOptions: { gridColumn: "5 / 6" }

        },

        FilterMultipleYear: {

            component: FilterCheckbox,

            props: { refetch : refetch, variables : variables, name : "year", parse : "number", loader : product_loader},

            dataName: "year",

            gridOptions: { gridColumn: "6 / 7" }

        },

        FilterBooleanRunFlat: {

            component: FilterBoolean,

            props: { refetch : refetch, variables : variables, name : "runFlat", loader : product_loader},

            dataName: false,

            gridOptions: { gridColumn: "7 / 8" }

        },

        FilterMultipleEnumStuds: {

            component: FilterCheckboxEnum,

            props: { refetch : refetch, variables : variables, name : "studs", query : STUDS_TYPES_ENUM, loader : product_loader },

            dataName: false,

            gridOptions: { gridColumn: "8 / 9" }

        },

        FilterTextSpecifications: {

            component: FilterText,

            props: { refetch : refetch, variables : variables, name : "specifications", placeholder : t ( "OEM" ), loader : product_loader },

            dataName: false,

            gridOptions: { gridColumn: "9 / 12" }

        },

        FilterTextStockQty: {

            component: FilterText,

            props: { refetch : refetch, variables : variables, name : "stockQtysFrom", parse : "number", placeholder : t ( "stockQtysFrom" ), loader : product_loader },

            dataName: false,

            gridOptions: { gridColumn: "1 / 2" }

        },

        FilterAvailability: {

            component: FilterAvailability,

            props: { refetch : refetch, variables : variables, name : "availability", loader : product_loader },

            dataName: false,

            gridOptions: { gridColumn: "2 / 5" }

        },

        // FilterBooleanShowOnlyAvailable: {

        //     component: FilterBoolean,

        //     props: { refetch : refetch, variables : variables, name : "showOnlyAvailable", loader : product_loader},

        //     dataName: false,

        //     gridOptions: { gridColumn: "2 / 3" }

        // },

    };

    /** @returns { JSX.Elements } TiresFilters */

    return (

        loading ? <CenterLoader /> :

        <div className={ styles.container }>

            <div className={ styles.content }>

                {
                
                    Object.keys ( componentData ).map
                    
                    ( ( componentKey, index ) => {

                            const { component: Component, props, gridOptions, dataName } = componentData[componentKey];

                            return (

                                <div style={ gridOptions } key={ index }>

                                    <Component { ...props } data={ dataName ? data.searchAttributes.tires[0][dataName] : null } />

                                </div>

                            );

                        }
                    
                    )
                    
                }

            </div>

            <FiltersReset variables={ variables } refetch={ refetch } />

        </div>

    );

};