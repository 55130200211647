import styles from "./FilterAvailability.module.css";

import { useTranslation } from "react-i18next";

import { useState, useEffect } from "react";

import { useQuery } from "@apollo/client";

import { PRODUCT_AVAILABILITY_ENUM } from "../../../../../../Queries/Products";

import ControlledCheckbox from "../../../../Global/Forms/ControlledCheckbox";

import CenterLoader from "../../../../Loaders/CenterLoader";


export default function FilterAvailability ( { data, variables, name, refetch, loader } ) {


    const { t } = useTranslation ();

    const [ availability, setAvailability ] = useState ( variables [ name ] || [] );

    const { data : availabilityData } = useQuery ( PRODUCT_AVAILABILITY_ENUM );


    /**
     * 
     * @description
     * 
     * Set the value to the state 
     * 
     */


    const handleChange = ( event ) => {

        if ( event.target.checked ) {

            setAvailability ( [...availability, event.target.value] );

        } else {

            setAvailability ( availability.filter ( ( vehicle_type ) => vehicle_type != event.target.value ) );

        }

    };


    /**
     * 
     * @event useEffect
     * 
     * @returns refetch the vehicle type collection after 1 second of inactivity
     * 
     */

    useEffect ( () => {

        if ( ! variables [ name ] && ! availability.length ) return;

        if ( variables [ name ] == availability ) return;

        refetch ( 

            { 
            
                [ name ] : availability.length ? availability : undefined,

                first: ( typeof variables.first == "undefined" ) ? variables.last : variables.first,

                last: undefined,

                before: undefined,

                after: undefined
            
            }
            
        );

    }, [ availability ] );


    /**
     * 
     * @event useEffect
     * 
     * @returns set value to empty string if variable is undefined
     * 
     */

    useEffect ( () => {
       
        if ( ! variables[ name ] ) { 
            
            setAvailability ( [] ); 
        
        }

    }, [ variables [ name ] ] );


    return (

        <div className={ styles.container }>

            {

                availabilityData?.__type?.enumValues?.map ( ( item, index ) => {

                    return (

                        <ControlledCheckbox 
                        
                            key={ index } 
                        
                            name={ item.name } 
                        
                            id={ item.name } 
                        
                            value={ item.name } 

                            action={ handleChange }

                            text={ t ( item.name ) }

                            disabled={ loader }

                            state={ availability.find ( ( availabilities ) => availabilities == item.name ) ? true : false }
                            
                        />

                    );

                } ) || <CenterLoader size={ 16 } />

            }

        </div>

    );


};