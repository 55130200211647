// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Filters_container__tv9tA > *:not( :last-child ) {\n\n    margin-bottom: 1rem;\n\n}\n\n.Filters_content__FKuu2 {\n\n    display: grid;\n\n    grid-template-columns: repeat( 11, 1fr );\n\n    align-items: center;\n\n    grid-gap: 1rem;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Products/Collection/Filters/Tires/Filters.module.css"],"names":[],"mappings":"AAAA;;IAEI,mBAAmB;;AAEvB;;AAEA;;IAEI,aAAa;;IAEb,wCAAwC;;IAExC,mBAAmB;;IAEnB,cAAc;;AAElB","sourcesContent":[".container > *:not( :last-child ) {\n\n    margin-bottom: 1rem;\n\n}\n\n.content {\n\n    display: grid;\n\n    grid-template-columns: repeat( 11, 1fr );\n\n    align-items: center;\n\n    grid-gap: 1rem;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Filters_container__tv9tA",
	"content": "Filters_content__FKuu2"
};
export default ___CSS_LOADER_EXPORT___;
