// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilterAvailability_container__IaTBV {\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: wrap;\n\n    gap: 1rem;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Products/Collection/Filters/Elements/FilterAvailability.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;;IAEb,mBAAmB;;IAEnB,eAAe;;IAEf,SAAS;;AAEb","sourcesContent":[".container {\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: wrap;\n\n    gap: 1rem;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FilterAvailability_container__IaTBV"
};
export default ___CSS_LOADER_EXPORT___;
