import styles from "./OrderCustomer.module.css";

import { useTranslation } from "react-i18next";

export default function OrderCustomer ( { data } ) {

    const 

    { t } = useTranslation ();

    return (

        <td data-title={ t ( "customer" ) }>

            <div className={ styles.container }>

                <div className={ styles.name }>

                    { 
            
                        data?.companyName?.length > 0 ? data.companyName : 

                        `${ data.firstName } ${ data.lastName }`
                    
                    }

                </div>

                <div className={ styles.phone }>

                    { data?.mobilePhoneNr || "-" }

                </div>

            </div>

        </td>
        
    );
    
};